import './App.css';
import React from 'react';
import doggo1 from './assets/doggos/1.jpg';
import doggo2 from './assets/doggos/2.png';
import doggo3 from './assets/doggos/3.png';
import doggo4 from './assets/doggos/4.jpg';
import doggo5 from './assets/doggos/5.jpg';
import doggo6 from './assets/doggos/6.png';
import doggo7 from './assets/doggos/7.jpg';
import doggo8 from './assets/doggos/8.png';
import doggo9 from './assets/doggos/9.png';

class App extends React.Component {
  doog = [
    doggo1,
    doggo2,
    doggo3,
    doggo4,
    doggo5,
    doggo6,
    doggo7,
    doggo8,
    doggo9,
  ]
  vids = [
    '3Oifsqw8Rj8',
    'yk2kdHkzI48',
    'nNLU796d500',
    'C6wGUFGEYDc',
    '_pid5tfoOy8',
    'uIeU-t-eWeg',
    'tuN_8blYZq0',
    '8YjFjyGOZlw',
    'BXJUtwlUKaA',
    'QQhFt8Mlvyg',
  ]
  constructor(){
    super();
    this.state = {}
    this.refresh = this.refresh.bind(this);
  }
  refresh(){
    this.setState({})
  }
  render(){
    return (
      <div className="App" style={{backgroundImage: `url(${this.doog[Math.floor(Math.random() * this.doog.length)]})`}}>
        <header className="App-header">
          <div className="name" onClick={this.refresh}>
            PANTSU.FUN
            </div>
            <div class="container">
            <iframe width="1120" height="630" src={`https://www.youtube.com/embed/${this.vids[Math.floor(Math.random() * this.vids.length)]}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen class="video"></iframe>    
            </div>
        </header>
      </div>
    );
  }
}
export default App;
